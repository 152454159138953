import {
  isDelete,
  isGet,
  isPost,
  isPut,
  remove,
  get,
  post,
  put,
} from './methods-003';

export default (params) => {
  const { verb } = params;
  if (isDelete(verb)) return remove(params);
  if (isGet(verb)) return get(params);
  if (isPost(verb)) return post(params);
  if (isPut(verb)) return put(params);
  return false;
};

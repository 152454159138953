import reduce from 'lodash/reduce';
import build from './methods-002';

export default (host, urls, token, apiVer) => reduce(urls, (out, url, key) => {
  const [verb, uri] = url;
  const params = {
    host,
    uri,
    token,
    apiVer,
    verb,
  };
  const fn = (data = {}) => build({ ...params, data });
  return { ...out, [key]: fn };
}, {});

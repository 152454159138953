import { createSlice } from '@reduxjs/toolkit';

export { default as fetchCards } from './fetchCards';
export { default as fetchChart } from './fetchChart';

const initialState = {};

const dashSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {},
});

export default dashSlice.reducer;

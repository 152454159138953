import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { USER_ROLE } from 'constants.js';
import request from 'utils/request';

const initialState = {
  isLogin: false,
  currentUser: {
    token: '',
    name: '',
    company: '',
    role: '',
  },
};

export const login = createAsyncThunk(
  'auth/login',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await request().login(values);
      return data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await request(token).logout();
      return data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetPassword = createAsyncThunk(
  'auth/reset-password',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await request().resetPassword(values);
      return data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetPasswordRequest = createAsyncThunk(
  'auth/reset-password-request',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await request().resetPasswordRequest(values);
      return data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    'auth/login/fulfilled': (state, { payload }) => ({
      ...state,
      isLogin: true,
      currentUser: {
        ...payload,
        role: USER_ROLE.Admin,
      },
    }),
    'auth/logout/fulfilled': () => initialState,
    'auth/reset-password-request/rejected': (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

const authReducer = authSlice.reducer;
export const token = (state) => state.currentUser.token;

export default authReducer;

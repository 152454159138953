import { createAsyncThunk } from '@reduxjs/toolkit';
import request from 'utils/request/index';
import { formatCents, formatInt } from 'utils/amount';

export const periodName = (orig) => {
  const period = `${orig}`;
  if (period === 'today') return 'сегодня';
  if (period === 'yesterday') return 'вчера';
  if (period === 'last7Days') return 'за 7 дней';
  if (period === 'last30Days') return 'за 30 дней';
  if (period === 'all') return 'за всю историю';
  return '';
};

export const prepare = (props) => {
  const {
    averageCheck,
    earnings,
    ordersCount,
    customersCount,
    period,
  } = props;
  return [
    {
      alias: '1',
      title: 'Количество заказов, шт',
      total: formatInt(ordersCount),
      icon: 'cart',
    },
    {
      alias: '2',
      title: 'Магазинов, шт',
      total: formatInt(customersCount),
      icon: 'shop',
    },
    {
      alias: '3',
      title: 'Выручка, руб',
      total: formatCents(earnings),
      icon: 'suitcase',
    },
    {
      alias: '4',
      title: 'Средний чек, руб',
      total: formatCents(averageCheck),
      icon: 'notebook-1',
    },
  ];
};

export default createAsyncThunk(
  'dash/fetchCards',
  async ({ token, period }, { rejectWithValue }) => {
    try {
      const response = await request(token).dashCards({ period });
      return prepare({ ...response.data, period });
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);

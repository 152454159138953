import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from 'utils/request';
import props from 'lodash/fp/props';
import orderBy from 'lodash/orderBy';
import isString from 'lodash/fp/isString';

export const fetch = createAsyncThunk(
  'items/fetch',
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await request(token).itemsFetch();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);

export const update = createAsyncThunk(
  'items/update',
  async ({ token, data }, { rejectWithValue }) => {
    try {
      const response = await request(token).itemsUpdate({ ...data });
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);

export const uploadPhoto =  createAsyncThunk(
  'items/upload-photo',
  async ({ token, id, photo }, { rejectWithValue }) => {
    try {
      const host = process.env.REACT_APP_API_HOST;
      const url = `${host}/v1/items/${id}/photo`;
      const formData  = new FormData();
      formData.append('photo', photo);
      const { data } = await axios.post(url, formData, {
        headers: {
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    } catch (err){
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  allIDs: [],
  byID: {},
  items: [],
  pageCount: 0,
  pageIndex: 0,
  loading: false,
  term: '',
  sortBy: [{ id: 'name', desc: false }],
};

const initItems = (allIDs, byID) => allIDs.map((itemID) => {
  const item = byID[itemID];
  const photo = item.photo ? item.photo : '/img/no_img.jpeg';
  const labels = {
    0: 'Запрещено',
    1: 'Уменьшает лимит',
    2: 'Не уменьшает лимит',
  };
  const label = labels[item.returns];
  return { ...item, photo, label, itemID };
});

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    setTerm: (state, { payload }) => {
      state.term = payload;
    },
    setSort: (state, { payload }) => {
      state.sortBy = payload;
    },
    receiveService(state, action) {
      const { items, pageCount, loading } = action.payload;
      state.items = items;
      state.pageCount = pageCount;
      state.loading = loading;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [fetch.fulfilled]: (state, { payload: { data: { allIDs, byID } } }) => {
      const items = initItems(allIDs, byID);
      return { ...state, allIDs, byID, items };
    },
  },
});

const sortItems = (items, sortBy) => orderBy (
  items,
  sortBy.map((colName) => colName.id),
  sortBy.map((colName)=> colName.desc ? 'desc' : 'asc')
);

const filterItems = (items, term) => {
  if (!isString(term)) return items;
  if (term === '') return items;
  return items.filter((item) => {
    const fields = props(['name', 'cat.name'], item);
    return fields.reduce((acc, field) => acc || field.includes(term), false);
  });
};

export const itemsList = ({ items, term, sortBy }) => filterItems(
  sortItems(items, sortBy),
  term
);

export const { setTerm, setSort } = itemsSlice.actions;

export default itemsSlice.reducer;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { token } from '../auth/authSlice';

const baseUrl = () => {
  const host = process.env.REACT_APP_API_HOST;
  const ver = process.env.REACT_APP_API_VER;
  return `${host}/v${ver}`;
};

export const api = createApi({
  reducerPath: 'apiAccount',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl(),
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState();
      const tkn = token(auth);
      if (tkn) {
        headers.set('authorization', `Bearer ${tkn}`);
      }
      return headers;
    },
  }),
  tagTypes: ['AccountSettings'],
  endpoints: (build) => ({
    getAccountSettings: build.query({
      query: () => '/account',
      providesTags: () => ['AccountSettings'],
    }),
    saveAccountSettings: build.mutation({
      query: (body) => ({ url: '/account', method: 'POST', body }),
      invalidatesTags: ['AccountSettings'],
    }),
  }),
});

export const {
  useGetAccountSettingsQuery,
  useSaveAccountSettingsMutation,
} = api;

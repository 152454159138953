import axios from 'axios';
import {
  appendToken,
  prepareVerb,
  url,
  query,
} from './methods-004';

export const isDelete = (verb) => prepareVerb(verb) === 'delete';
export const isGet = (verb) => prepareVerb(verb) === 'get';
export const isPost = (verb) => prepareVerb(verb) === 'post';
export const isPut = (verb) => prepareVerb(verb) === 'put';

export const remove = (params) => axios({
  method: 'delete',
  url: url(params.host, params.uri, params.apiVer),
  data: appendToken(params.data, params.token),
});

export const get = (params) => axios({
  method: 'get',
  url: `${url(params.host, params.uri, params.apiVer)}?${query(params.data, params.token).join('&')}`,
});

export const post = (params) => axios({
  method: 'post',
  url: url(params.host, params.uri, params.apiVer),
  data: appendToken(params.data, params.token),
});

export const put = (params) => axios({
  headers: {
    'Authorization': `Bearer ${params.token}`,
  },
  method: 'put',
  url: url(params.host, params.uri, params.apiVer),
  data: params.data,
  // data: appendToken(params.data, params.token),
});

export default {
  dashCards: ['get', 'dash/cards'],
  dashChart: ['get', 'dash/chart'],
  login: ['post', 'users/login'],
  logout: ['get', 'users/logout'],
  resetPassword: ['post', 'users/reset-password'],
  resetPasswordRequest: ['post', 'users/reset-password-request'],
  // regionsFetch: ['get', 'regions'],
  // regionCreate: ['post', 'regions'],
  // regionPeriodCreate: ['post', 'shipping-periods'],
  // regionPeriodRemove: ['delete', 'shipping-periods'],
  // regionPeriodUpdate: ['put', 'shipping-periods'],
  itemsFetch: ['get', 'items'],
  itemsUpdate: ['put', 'items'],
  catsFetch: ['get', 'cats'],
  catsReOrder: ['post', 'cats/re-order'],
};

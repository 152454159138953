import { createAsyncThunk } from '@reduxjs/toolkit';
import request from 'utils/request/index';
// import { prepare } from './methods-001';

export default createAsyncThunk(
  'dash/fetchChart',
  async ({ token, period }, { rejectWithValue }) => {
    try {
      const response = await request(token).dashChart({ period });
      return response.data.chart;
      // return prepare(response.data.chart);
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);

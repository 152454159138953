import reduce from 'lodash/reduce';

export const appendToken = (data, token) => {
  if (typeof token !== 'string') return { ...data };
  if (`${token}` === '') return { ...data };
  return { ...data, access_token: token };
};

export const query = (data, token) => reduce(data, (out, val, key) => {
  out.push(`${key}=${val}`);
  return out;
}, [`access_token=${token}`]);

export const prepareVerb = (verb) => `${verb}`.toLowerCase();

export const url = (host, uri, ver) => `${host}/v${ver}/${uri}`;

import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from 'utils/request';

export const fetch = createAsyncThunk(
  'cats/fetch',
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await request(token).catsFetch();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);

export const reOrder =  createAsyncThunk(
  'cats/reOrder',
  async ({ token, id, prev, next }, { rejectWithValue }) => {
    try {
      const { data } = await request(token).catsReOrder({id, prev, next});
      return data;
    } catch (err){
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);

export const uploadPhoto =  createAsyncThunk(
  'cats/upload-photo',
  async ({ token, id, photo }, { rejectWithValue }) => {
    try {
      const host = process.env.REACT_APP_API_HOST;
      const url = `${host}/v1/cats/${id}/photo`;
      const formData  = new FormData();
      formData.append('photo', photo);
      const { data } = await axios.post(url, formData, {
        headers: {
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    } catch (err){
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  cats: [],
};

const catsSlice = createSlice({
  name: 'cats',
  initialState,
  reducers: {
    setList: (state, { payload }) => ({ ...state, cats: payload }),
  },
  extraReducers: {
    [fetch.fulfilled]: (state, { payload }) => ({ ...state, cats: payload }),
    },
  },
);

export const { setList } = catsSlice.actions;

export default catsSlice.reducer;

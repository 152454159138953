import { lazy } from 'react';
import { DEFAULT_PATHS } from 'config.js';

const NotFound = lazy(() => import('snarta/default/NotFound'));
const Login = lazy(() => import('snarta/default/Login'));
const PasswordResetReq = lazy(() => import('snarta/default/PasswordResetReq'));
const PasswordSendInstr = lazy(() => import('snarta/default/PasswordSendInstr'));
const PasswordReset = lazy(() => import('snarta/default/PasswordReset'));
const PasswordChanged = lazy(() => import('snarta/default/PasswordChanged'));
const Register = lazy(() => import('snarta/default/Register'));
const Unauthorized = lazy(() => import('snarta/default/Unauthorized'));
const InvalidAccess = lazy(() => import('snarta/default/InvalidAccess'));
const App = lazy(() => import('App.js'));
const Home = lazy(() => import('snarta/default/Home'));

/*
{ path: "/path", exact: true, component: ViewHome },
// or
{ path: "/path", component: ViewHome},
// or
{ path: "/path", exact: true, redirect: true, to: "/redirectPath" },
*/
const defaultRoutes = [
  { path: DEFAULT_PATHS.NOTFOUND, exact: true, publicOnly: true, component: NotFound },
  { path: DEFAULT_PATHS.LOGIN, exact: true, publicOnly: true, component: Login },
  { path: DEFAULT_PATHS.REGISTER, exact: true, component: Register },
  { path: DEFAULT_PATHS.PASSWORD_RESET_REQ, exact: true, component: PasswordResetReq },
  { path: DEFAULT_PATHS.PASSWORD_SEND_INSTR, exact: true, component: PasswordSendInstr },
  { path: DEFAULT_PATHS.PASSWORD_RESET, component: PasswordReset },
  { path: DEFAULT_PATHS.PASSWORD_CHANGED, exact: true, component: PasswordChanged },
  { path: DEFAULT_PATHS.UNAUTHORIZED, exact: true, component: Unauthorized },
  { path: DEFAULT_PATHS.INVALID_ACCESS, exact: true, component: InvalidAccess },
  { path: DEFAULT_PATHS.APP, component: App },
  { path: '/dashboard', exact: true, component: Home },
];

export default defaultRoutes;
